import { Account } from '@air/api';
import { matchesAirror, WORKSPACE_INTEGRATION_SSO_NOT_ENABLED } from '@air/errors';

export const getIsSAMLEnforced = async (email: string) => {
  try {
    const workspaces = await Account.getAccountSSOOptions(email);

    return workspaces.length > 0
      ? !!workspaces.find((workspace) => !!workspace.enforce && !!workspace.isMember)
      : false;
  } catch (error) {
    if (matchesAirror(error, WORKSPACE_INTEGRATION_SSO_NOT_ENABLED)) {
      return false;
    } else {
      throw error;
    }
  }
};
